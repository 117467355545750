.grid {
    display: inline-grid;
    width: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    grid-gap: 0;
}

.bannerText {
    font-size: 30px;
}

.bannerInfo {
    font-size: 20px;
}

.rightPanel {
    padding: 40px 120px 0 120px;
    margin: 0 auto;
    text-align: center;
}

.rightPanel, img {
    max-width: 200px;
}

.downloadAllSection {
  padding: 0 0 60px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.imagesSection {
  padding: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.downloadButton {
  background-color: #5ac686;
}

.downloadButtonInactive {
  background-color: #858687;
}

.linkSection {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .linkSection {
    display: block;
    align-items: baseline;
    justify-content: space-between;
    margin-right: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .grid {
    display: inline-grid;
    width: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: 0.1fr 1fr 0.1fr;
    grid-gap: 0;
  }
  .rightPanel {
    display: none;
  }
  .imagesSection {
    padding-top: 80px;
  }
  .downloadAllSection {
    display: none;
  }
}

@media (pointer: coarse) {
  /* Hide on mobile */
  .downloadAllSection {
    display: none;
  }
}
