.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

.logo {
  width: 111px;
}

