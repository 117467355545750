.grid {
    display: inline-grid;
    width: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: 0.2fr 1fr 1fr 0.2fr;
    grid-gap: 0;
}

@media only screen and (max-width: 800px) {
    .grid {
        padding-left: 20px;
        padding-right: 20px;
        grid-template-columns: 1fr;
        max-width: 90%;
    }
    .rightPanel {
        display: none;
    }
}

.bannerText {
    font-size: 40px;
}

.bannerInfo {
    font-size: 20px;
}

.rightPanel {
    padding: 40px 120px 0 120px;
    margin: 0 auto;
    text-align: center;
}

.rightPanel, img {
    max-width: 200px;
}
