@import-normalize;

html {
  background: #53F2FE url(./assets/header.jpg) no-repeat fixed center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  margin: 0;
  font-family: neue-haas-grotesk-text, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

button {
  background-color: #ff746f;
  border-radius: 3px;
  letter-spacing: 1px;
  color: white;
  border-style: none;
  padding: 6px 12px;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
}

a {
  color: white;
  text-decoration: none;
}
